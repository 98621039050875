export const brand = {
  primary: '#0078CC',
  //primary: '#5348FF',
  //primary: '#1ABC9C',
  //primary: '#D42210',
  //primary: '#D96E0E',
  //primary: '#9B59B6',
  //primary: '#6420E5',
  secondary: 'rgba(10,52,80,1)',
};
