import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import { theme } from '../../config/theme';

const nameSpacing = 0.3;

const GivenName = styled.span({
  position: 'relative',
  textTransform: 'uppercase',
});

const FamilyName = styled.span({
  position: 'relative',
  textTransform: 'uppercase',
  paddingLeft: `${nameSpacing}em`,
});

export const Logo = ({ className }) => (
  <Link
    className={className}
    css={css`
      display: flex;
      justify-content: center;
      padding: 0 1.5em 0.2em 3em;
      letter-spacing: 1px;
      position: relative;
      font-size: 1.5em;
      text-decoration: none;
      @media (min-width: 600px) {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: ${theme.colors.paper};
        }
      }
    `}
    to="/"
    aria-label="Visit home page"
  >
    <GivenName>Alisha</GivenName>
    <FamilyName>Jeddeloh</FamilyName>
  </Link>
);
