import React from 'react';
import { Boilerplate } from './Boilerplate';
import { Children } from 'react';
import styled from '@emotion/styled';
import { theme } from '../../config/theme';
import { Nav } from './Nav';
import { Texture } from './Texture';
import { css } from '@emotion/core';

const Layout = styled.section`
  display: flex;
  align-items: stretch;
  min-height: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div({
  display: 'none',
  position: 'relative',
  width: '20vw',
  // backgroundColor: `${theme.brand.secondary}`,
  // backgroundColor: `${theme.colors.body_color}`,
  ['@media (min-width: 600px)']: {
    minWidth: '7em',
    display: 'block',
  },
});

const ContentArea = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 8em 2em 0;
  ['@media (min-width: 600px)']: {
    padding-top: 5em;
    padding: 2em 5em 0;
  },
`;

const Page = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  width: 100%;
  max-width: 38em;
  margin: 0 auto;
`;

export const InnerPageLayout = ({ site, children, twoColumnLayout }) => {
  const [sidebar, content] = twoColumnLayout ? children : [null, children];

  return (
    <Boilerplate site={site}>
      <Layout>
        {sidebar}
        <ContentArea>
          <Page>{content}</Page>
        </ContentArea>
        <Nav />
      </Layout>
    </Boilerplate>
  );
};
