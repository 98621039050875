import React from 'react';

export const LineIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    x="0px"
    y="0px"
  >
    <rect x="0" y="15" width="32" height="2" />
  </svg>
);
