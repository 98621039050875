import React, { useState } from 'react';
import { Logo } from './Logo';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import { fonts } from '../lib/typography';
import styled from '@emotion/styled';
import { MenuIcon } from './MenuIcon';
import { theme } from '../../config/theme';
import { Texture } from './Texture';
import { LinkUnderline } from './LinkUnderline';

const NavLink = styled(Link)`
  display: block;
  position: relative;
  padding: 0.25em;
  font-size: 1.2em;
  text-align: center;
  color: ${theme.colors.headerColor};
  font-family: ${fonts.test};
  text-decoration: none;
  &:active {
    color: ${theme.colors.headerColor};
  }
  @media (min-width: 600px) {
    font-size: 1.3em;
    color: black;
    margin-left: 3em;
  }
`;

// let activeStyle = {
//   color: 'deepPink',
//   ':after': {
//     content: '',
//     position: 'absolute',
//     top: '100%',
//     left: '0',
//     width: '100%',
//     height: '5px',
//   },
//   // textDecoration: 'underline',
// };

const NavLinks = styled.div(({ mobileNavOpen }) => ({
  display: mobileNavOpen ? 'block' : 'none',
  fontSize: '.7em',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  order: '2',
  ['@media (min-width: 600px)']: {
    order: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
}));

const MobileMenuButton = ({ onClick, mobileNavOpen }) => {
  return (
    <button
      onClick={onClick}
      css={{
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.headerColor,
        fill: theme.colors.headerColor,
        ['@media (min-width: 600px)']: {
          display: 'none',
        },
      }}
    >
      <MenuIcon marginRight="0.5em" morphToClose={mobileNavOpen} />
      Menu
    </button>
  );
};

const MobileMenuLayout = styled.div({
  display: 'flex',
  justifyContent: 'center',
  ['@media (min-width: 600px)']: {
    display: 'none',
  },
});

const NavLayout = styled.div(({ mobileNavOpen }) => [
  {
    order: 2,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative', // sit over texture
    margin: '0 auto',
    padding: '0.5em 3em 0.5em 0',
    // maxWidth: '50em',
    backgroundColor: mobileNavOpen ? 'rgba(0,0,0,0.2)' : 'transparent',
    ['@media (max-width: 600px)']: {
      flexDirection: 'column',
      marginBottom: 0,
    },
  },
]);

export const Nav = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  return (
    <nav
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        font-family: ${fonts.test};
        // background-color: ${theme.brand.secondary};
        @media (min-width: 600px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: transparent;
        }
      `}
    >
      <NavLayout mobileNavOpen={mobileNavOpen}>
        <MobileMenuLayout>
          <MobileMenuButton
            mobileNavOpen={mobileNavOpen}
            onClick={() => setMobileNavOpen(!mobileNavOpen)}
          />
        </MobileMenuLayout>
        <Logo />
        <NavLinks mobileNavOpen={mobileNavOpen}>
          <NavLink
            to="/about/"
            aria-label="Visit about page"
            activeClassName="active"
          >
            About
            <LinkUnderline />
          </NavLink>
          <NavLink
            to="/work/"
            aria-label="Visit writing page"
            activeClassName="active"
          >
            Work
            <LinkUnderline />
          </NavLink>
          <NavLink
            to="/notes/"
            aria-label="Visit blog page"
            activeClassName="active"
          >
            Notes
            <LinkUnderline />
          </NavLink>
        </NavLinks>
      </NavLayout>
    </nav>
  );
};
