import styled from '@emotion/styled';

export const Texture = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.14;
  background-image: url('/images/clean-gray-paper.png');
`;
