import React from 'react';
import { LineIcon } from './LineIcon';

const lineStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  transition: 'opacity 0.2s, transform 0.3s',
};

const TopLine = ({ morphToClose }) => {
  const openStyles = { opacity: 1 };
  const closedStyles = { opacity: 0 };
  return (
    <LineIcon
      css={[
        lineStyles,
        {
          transform: 'translateY(-30%)',
        },
        morphToClose ? closedStyles : openStyles,
      ]}
    />
  );
};

const MidLine1 = ({ morphToClose }) => {
  const closedStyles = { transform: 'rotate(45deg) scale(1.1, 1)' };
  return <LineIcon css={[lineStyles, morphToClose ? closedStyles : null]} />;
};

const MidLine2 = ({ morphToClose }) => {
  const closedStyles = { transform: 'rotate(-45deg) scale(1.1, 1)' };
  return <LineIcon css={[lineStyles, morphToClose ? closedStyles : null]} />;
};

const BottomLine = ({ morphToClose }) => {
  const openStyles = { opacity: 1 };
  const closedStyles = { opacity: 0 };
  return (
    <LineIcon
      css={[
        lineStyles,
        {
          transform: 'translateY(30%)',
        },
        morphToClose ? closedStyles : openStyles,
      ]}
    />
  );
};

export const MenuIcon = ({ morphToClose, marginRight }) => {
  return (
    <div
      css={[
        {
          position: 'relative',
          height: '.9em',
          width: '.9em',
        },
        { marginRight },
      ]}
    >
      <TopLine morphToClose={morphToClose} />
      <MidLine1 morphToClose={morphToClose} />
      <MidLine2 morphToClose={morphToClose} />
      <BottomLine morphToClose={morphToClose} />
    </div>
  );
};
