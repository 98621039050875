import Typography from 'typography';
import '../fonts/fonts.css';
import { colors } from '../../config/colors';

export const fonts = {
  regular: 'Poppins',
  regularItalic: 'Inter UI Regular Italic',
  semibold: 'Inter UI Semibold',
  semiboldItalic: 'Inter UI Semibold Italic',
  bold: 'Inter UI Bold',
  boldItalic: 'Inter UI Bold Italic',
  test: 'Test',
};

const typography = new Typography({
  baseFontSize: '18px',
  baseLineHeight: 1.55,
  headerLineHeight: 1.4,
  headerFontFamily: [fonts.test, 'serif'],
  bodyFontFamily: [fonts.regular, 'sans-serif'],
  bodyWeight: '300',
  headerColor: `${colors.headerColor}`,
  bodyColor: `${colors.bodyColor}`,
  // googleFonts: [
  //   {
  //     name: 'Poppins',
  //     weights: ['300', '300i'],
  //   },
  // ],

  overrideStyles: ({ rhythm }) => ({
    // h1: {
    //   color: 'hsla(0,0%,0%,0.75)',
    // },
    // h2: {
    //   color: 'hsla(0,0%,0%,0.775)',
    // },
    // h3: {
    //   color: 'hsla(0,0%,0%,0.8)',
    // },
    'h1,h2,h3,h4,h5,h6': {
      fontWeight: 400,
      lineHeight: 1,
    },
    'h1,h2,h3,h4': {
      lineHeight: 1.25,
      marginTop: rhythm(1),
      marginBottom: rhythm(1 / 2),
    },
  }),
});
// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
