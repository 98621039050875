import { theme } from '../../config/theme';
import styled from '@emotion/styled';

export const LinkUnderline = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  .active & {
    color: ${theme.colors.headerColor};
  }
  &:after {
    content: '';
    // margin-top: -3px;
    border-bottom: 3px solid ${theme.colors.headerColor};
    width: 0;
    transition: width 0.2s;
  }
  .active &:after,
  a:hover &:after {
    content: '';
    // margin-top: -3px;
    width: 100%;
    border-bottom: 3px solid ${theme.colors.headerColor};
  }
`;
