import { brand } from './brand';

export const colors = {
  // primary_light: brand.primary,
  bodyOnSecondary: 'rgba(165,165,165,1)',
  gray: '#D3D3D3',
  black: '#000',
  white: '#fff',
  bg_color: 'rgb(255, 252, 242)',
  body_color: 'rgba(0,0,0,0.9)',
  headerColor: 'rgb(33, 32, 30)',
  bodyColor: 'rgba(56, 55, 52)',
  link_color: brand.primary,
  // link_color_hover: brand.primary,
  red: '#E86C60',
  green: '#29B573',
  paper: 'rgb(255, 252, 242)',
};
